// Since this is a .js file, this code (which is a copy of the code inside main.ts) exists here
//   to accommodate path (pageId) injection when doing segment track and page calls.
function getAnalyticsLocationPageId() {
  let pageId = window.location.hash;

  try {
    if (pageId.length > 1 && pageId.substr(0, 1) === '#') {
      pageId = pageId.substr(1);
    }
  } catch (error) {
    return '';
  }
  // anonymize URL
  pageId = pageId.replace(/[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}/gi, '<id>');
  pageId = pageId.replace(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}/gi, '<email>');
  // additional url stripping for openid related urls
  pageId = pageId.replace(
    /(openid\.intuit\.com%252FIdentity-)([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})(\/)([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/gi,
    '$1<id>$3<id>'
  );

  return pageId;
}

export function identify(userId, traits) {
  try {
    window.analytics.identify(userId, traits);
  } catch (exception) {
    // placeholder
  }
}

/**
 * @deprecated When creating new segment events define the event properties to have type definitions, and not be unknown.
 */
type UnknownProperties = Record<string, unknown>;

type OptionalEventProperties = {
  companyId: string;
  context: string;
  parentNodeId: string;
  buttonName: string;
  numOfRequiredApprovals: string;
  userId: string;
};

interface SecondaryBankLoginEventLookup {
  'Automatic Bank Connection Initiated': UnknownProperties;
  'Automatic Bank Connection Submitted': UnknownProperties;
  'Automatic Bank Verification Cancelled': UnknownProperties;
  'Automatic Bank Verification Completed': UnknownProperties;
  'Automatic Bank Verification Force Void Cheque': UnknownProperties;
}

interface AccountingSoftwareIntegrationEventLookup {
  'Accounting Platform Connection Initiated': UnknownProperties;
  'Accounting Platform Connected': UnknownProperties;
  'Accounting Platform Disconnected': UnknownProperties;
  'Accounting Platform Sync': UnknownProperties;

  'Intuit Sign-in Initiated': {
    source: string;
  };
  'Intuit Sign-in Completed': {
    source: string;
  };
}

interface OcrTrackEventLookup {
  'OCR - Navigate to Document Tab': UnknownProperties;
  'Capture Document Uploaded': UnknownProperties;
  'Capture Document Published': UnknownProperties;
  'Capture Document Deleted': UnknownProperties;
  'Capture Document Archive Visited': UnknownProperties;
  'Capture Document Supplier Changed': UnknownProperties;
  'Capture Document Supplier Rule Changed': UnknownProperties;
}

interface FormTrackEventLookup {
  'Button Clicked': {
    dialogInstanceId?: string;
    buttonName: string;
  };
  'Form Field Captured': {
    formInstanceId: string;
    formName: string;
    fieldName?: string;
    isFieldEmpty?: boolean;
    fieldValue?: string;
  };
  'Form Presented': {
    formInstanceId: string;
    formName: string;
  };
  'Form Submitted': {
    formInstanceId: string;
    formName: string;
    fieldName?: string;
    fieldValue?: string;
    planSelected?: string;
    submissionStatus?: string;
  };
  'Form Error Received': {
    errorMessage: string;
    formInstanceId: string;
    formName: string;
    planSelected?: string;
  };
}

interface DialogTrackEventLookup {
  'Dialog Presented': {
    dialogInstanceId: string;
    dialogName: string;
    context: string;
    companyId: string;
    parentNodeId: string;
  };
  'Dialog Dismissed': {
    dialogInstanceId: string;
    buttonName: string;
    dialogName: string;
    context: string;
    companyId: string;
    parentNodeId: string;
  };
  'Dialog Acknowledged': {
    buttonName: string;
    dialogName: string;
    dialogInstanceId: string;
    context: string;
    contactType: string;
    contactId: string;
  };
}

interface FeatureTrackEventLookup {
  'Add Card Selected': {
    buttonName: string;
    dialogInstanceId: string;
    dialogName: string;
    context: string;
    companyId: string;
    parentNodeId: string;
    contactType?: string;
    contactId?: string;
  };
  'Error Message Presented': {
    dialogInstanceId: string;
    dialogName: string;
    context: string;
    companyId: string;
    parentNodeId: string;
  };
  'Contact Details Error Message Presented': {
    dialogInstanceId: string;
    dialogName: string;
    context: string;
    contactType: string;
    contactId: string;
    companyId: string;
    parentNodeId: string;
  };
  'Page Presented': {
    dialogInstanceId: string;
    dialogName: string;
    context: string;
    companyId: string;
    parentNodeId: string;
  };
  'Learn more link Selected': {
    dialogInstanceId: string;
    buttonName: string;
    context: string;
    companyId: string;
    parentNodeId: string;
    dialogName?: string;
  };
  'Card Payment Confirmation Presented': {
    dialogInstanceId: string;
    dialogName: string;
    context: string;
    contactType: string;
    contactId: string;
    companyId: string;
    parentNodeId: string;
  };
  'Delete Card': {
    dialogInstanceId: string;
    dialogName: string;
    buttonName: string;
    context: string;
    companyId: string;
    parentNodeId: string;
  };

  'Automatic ID Verification Cancelled': {
    companyId: string;
    customerInternalReference: string;
    provider: string;
    transactionReference: string;
  };
  'Automatic ID Verification Cancel Initiated': {
    companyId: string;
    customerInternalReference: string;
    provider: string;
    transactionReference: string;
  };
  'Automatic ID Verification Initiated': {
    companyId: string;
    customerInternalReference: string;
    provider: string;
    transactionReference: string;
  };
  'Automatic ID Verification Submitted': {
    companyId: string;
    customerInternalReference: string;
    provider: string;
    transactionReference: string;
  };
  'Company Verification - Initiated': {
    source: string;
    companyId: string;
  };
  'Company Verification - 1.1 Business Basic Continue': {
    source: string;
    companyId: string;
  };
  'Company Verification - 1.2 Business Address Continue': {
    source: string;
    companyId: string;
  };
  'Company Verification - 1.3 Operating Complete': {
    source: string;
    companyId: string;
  };
  'Company Created': {
    formInstanceId: string;
    formName: string;
    companyId: string;
  };

  'Identity Verification - Initiated': {
    source: string;
  };
  'Identity Verification - Photo ID Verification': {
    source: string;
  };
  'Identity Verification - 1.1 Identity Basic Continue': {
    source: string;
  };
  'Identity Verification - 1.2 Residential Address Continue': {
    source: string;
  };
  'Identity Verification - 1.3 Online Verification Continue': {
    source: string;
  };

  'Bank Verification Initiated': {
    source: string;
    companyId: string;
  };

  'Set Password and Continue': {
    source: string;
  };
  'User Signed In': {
    source: string;
  };
  'User Signed Out': {
    source: string;
  };

  'Business Type Selected': { source: string; classification: string };

  TwoFactorAuthenticationSuccess: {
    source: string;
  };

  'Email Preferences Changed': {
    companyId: string;
    fieldName: string;
  };

  'New Payment Request Created': UnknownProperties;
}

type TrackEventLookup = FeatureTrackEventLookup &
  FormTrackEventLookup &
  DialogTrackEventLookup &
  OcrTrackEventLookup &
  AccountingSoftwareIntegrationEventLookup &
  SecondaryBankLoginEventLookup;

export type TrackEvents = keyof TrackEventLookup;
export type TrackEventValues<E extends TrackEvents> = TrackEventLookup[E] &
  Partial<OptionalEventProperties>;

export function track<E extends TrackEvents>(eventType: E, eventValue: TrackEventValues<E>) {
  try {
    window.analytics.track(eventType, {
      ...eventValue,
      path: getAnalyticsLocationPageId(),
    });
  } catch (exception) {
    // placeholder
  }
}

export function page(eventType, eventValue) {
  try {
    window.analytics.page(eventType, {
      ...eventValue,
      path: getAnalyticsLocationPageId(),
    });
  } catch (exception) {
    // placeholder
  }
}

export function reset() {
  try {
    window.analytics.reset();
  } catch (exception) {
    // placeholder
  }
}
